import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Http, Response } from '@angular/http';
import { environment } from '../../../environments/environment';
import { DDMS } from "../../../provider/DDMS";
import * as moment from "moment";
import { root } from 'rxjs/internal/util/root';
import { ApiServiceService } from 'src/app/api-service.service';
import { FolderService } from 'src/app/dashboard/explorer/folder/folder.service';
import { SubHeaderService } from 'src/app/dashboard/sub-header/sub-header.service';
import { StorageRequestComponent } from 'src/app/sheard/navbar/storage-request/storage-request.component';
declare var $: any
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarpageComponent implements OnInit {
  addfoldername: any
  user: any;
  localStorageUser: any;
  hidetextCat = true;
  hidetextCatTemp = true;
  hidetextCa = true;
  explo = true;
  application_document: any;
  currentPathArray=[];
  module_list =[]
  subchild =[]
  requestapprovalcount = 0
  storage: any;
  usedstorage=0
  remaining_storage= 0
  percentage= 1
  limitofstorage = 0
  redalert = false

  // StorageRequestComponent
  @ViewChild(StorageRequestComponent) storagePrev: StorageRequestComponent;
  totalnotification= 0
  notificationevent= false
  paramdata: any;

  // searchvalue
  @ViewChild('searchvalue') searchvalue: ElementRef
  constructor(private toastr: ToastrService, public ddms: DDMS, public tostr:ToastrService,
    private shareservice: SubHeaderService,
    private router: Router,private route: ActivatedRoute,public folderapi: FolderService,
    private http: Http, public api: ApiServiceService) { 
    this.localStorageUser =  JSON.parse(localStorage.getItem('USER'))
    this.user = {}
  
    if(this.api.loggedin()==false&&localStorage.getItem('USER')){
      this.refresh();
      }

    }
  showsubfolder = true
  function :any
  datahide = false
  image: any
  user_type: any
  newcompany_logo: any
  user_id: any
  alldocument = []
  allTempdocument = []
  opencard = false
  hidetext = false
  Review: any = []
  getallnotification=[]
  level: any;
  manageSection:any={
    Trash: true,
    categories: true,
    mail: true,
    templates: true
  };

  ngOnInit() {
    let login_user = JSON.parse(localStorage.getItem("USER"));
    this.user_type = login_user.User_type
    this.user_id = login_user._id
    this.level = login_user
    this.loginuserprofile = login_user;
 
    
    if(this.localStorageUser.User_type=='SuperAdmin'){
    
    
      this.getmodulelist()
    }else{
      this.getusermodulelist()
    
    }
    this.usereditfolder()
  
    if (this.ddms.subsVar == undefined) {
      this.ddms.subsVar = this.ddms.invokeFirstComponentFunction.subscribe((name: string) => {
        this.getuserdata()
        
      });
    }
    if (this.ddms.supSubVar == undefined) {
      this.ddms.supSubVar = this.ddms.invokeFirstComponentFunction2.subscribe((name: string) => {
        this.getallTempdocument()
       
      });
    }

    if (this.ddms.subs == undefined) {
      this.ddms.subs = this.ddms.invokeFirstComponent.subscribe((name: string) => {
        name
        this.getnotification()
      });
    }
    this.getnotification()
    this.getuserdata()
    this.companylogo()
    this.userprofile()

    this.folderapi.currentpath$.subscribe(val => {
     
    });
  //   setTimeout(()=> {
  //     this.tostr.success("Sucessfully Updated");
  //   
  // }, 5000);
  // this.tostr.success("Sucessfully Updated");
  //   start of event 
  //   const eventSource = new EventSource(`${environment.apiBaseURL}/notification_action/events`);
  //   eventSource.onmessage = (event) => {
    
  //     const notification = JSON.parse(event.data);
  // this.notificationevent = true
  // this.toastr.success(notification.Title, 'Title', { timeOut: 5000 });
  // this.getnotification()
 
  //   };

  //  end of event 
    // this.getall()

    this.route.queryParams.subscribe(params => {
      this.paramdata = params
//   if(params['q']){
// this.searchvalue.nativeElement.value = params['q']
//   }
    //   if(params && Object.keys(params).length > 0){
    //     this.router.navigate(['app/explorer/' + params.name], { queryParams: { name: params.name, filter: params.filter,type:'normal',q:value } })
    //   }else{
    //     this.router.navigate(['app/explorer/' + 'root'], { queryParams: { name: 'root', filter: 'az',type:'normal',q:value } })
      })
  }

  ngOnChanges() {
    this.function1()
  }

  refresh() {
    if(this.api.loggedin()==false&&localStorage.getItem('USER')){
    localStorage.clear();
 
    window.location.reload();
    }
  
}

  function1() {
    alert("data")
  }
  loginuserprofile; any
  // ####### -------------- start ------------------ ######


  gotodashboradpage() {
    this.router.navigateByUrl('/app/dashboard')
  }

  openrequestmodel(){
    this.storagePrev.openstorage()
  }

  getsearchingvalue(value){
   
    if(value){   
      const currentRoute = this.router.url;
   

      if (currentRoute.startsWith('/app/explorer/root')) {
     
        // Navigate to 'app/explorer/root' with updated query parameters
        this.router.navigate(['app/explorer/root'], {
          queryParams: { name: 'root', filter: 'az', q: value }
        });
      } else if(currentRoute.startsWith('/app/explorer/')){
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { name: 'root', filter: 'az', q: value }
        });
      }
    //   if(currentRoute=='app/explorers/root'){
    //     this.router.navigate(['app/explorer/' + 'root'], { queryParams: { name: 'root', filter: 'az',q:value } })
    //   }else{

    //   }

    //   // this.router.navigate(['app/explorers/search'],{ queryParams: { q: value } })
    // }else{
    //   this.router.navigate(['app/explorer/' + 'root'], { queryParams: { name: 'root', filter: 'az' } })
    }
  }
// getsearchvalue
getsearchvalue(value){

  this.router.navigate(['app/explorer/' + 'root'], { queryParams: { name: 'root', filter: 'az',type:'normal',q:value } })
  //   }

    if(this.paramdata && Object.keys(this.paramdata).length > 0){
      this.router.navigate(['app/explorer/' + this.paramdata.name], { queryParams: { name: this.paramdata.name, filter: this.paramdata.filter,type:'normal',q:value } })
    }else{
      this.router.navigate(['app/explorer/' + 'root'], { queryParams: { name: 'root', filter: 'az',type:'normal',q:value } })
  
      // this.router.navigate(['app/explorer/' + this.paramdata.name], { queryParams: { name: this.paramdata.name, filter: this.paramdata.filter,type:'normal',q:value } })

  }
}


 
  dateformat(date) {
 
    return moment(date)
      .startOf("second")
      .fromNow();
  }

  getnotification() {
    if (this.level.User_type == "SuperAdmin") {
      let obj = {
        reciver_id: this.level._id,
        userdetail : this.folderapi.login_userID._id
      }
      this.folderapi.postMethod('/notification_action/notificationsonnavbarofadmin', obj)
        .subscribe((data: any) => {
      
     
       
          this.getallnotification = data.data
         
this.totalnotification = data.totalnotification

        })

    } else {

      let obj = {
        reciver_id: this.level._id,
        userdetail : this.folderapi.login_userID._id
      }
      this.folderapi.postMethod('/notification_action/notificationsonnavbar', obj)
        .subscribe((data: any) => {
      
       
       
          this.getallnotification = data.data
          this.requestapprovalcount = data.detail
this.totalnotification = data.totalnotification

        })
    }
  }

  gotoimage(data){
   
      return `${environment.buildurl}/profile/${data.sender.user_image}`
    }

 async userprofile() {
    let url = this.api.baseAPIPath + '/user/userprofile'
    let obj = {
      _id: this.loginuserprofile.Asign_storage,
      userid : this.loginuserprofile._id
    }
    this.folderapi.postMethod('/user/userprofile', obj)
      .subscribe((data: any) => {
    
  
        this.storage = data.storage
        this.remaining_storage = data.remaining_storage
        this.usedstorage = data.user_storage
     

       let   overallStorage =  this.storage
      this.percentage = (this.usedstorage / overallStorage) * 100
      // this.limitofstorage =this.storage - environment.limitstorage 
   
      if(this.percentage>=90){
     
        this.redalert = true
      }


      
 
     
      })
  }
  companylogo() {
    let url = this.api.baseAPIPath + `/admin/companylogo`
    this.folderapi.getmethod('/admin/companylogo')
      .subscribe((data: any) => {
     
        this.image = data
        if (data.length > 0) {
          this.newcompany_logo = this.image[0].company_logo
        }
        else {

        }
      })
  }

  getall() {
    let url = this.api.baseAPIPath + '/documentaudit/alldocument'
    this.folderapi.getmethod('/documentaudit/alldocument')
      .subscribe((data: any) => {
        if (data.length > 0) {
          const value = data.filter((e) => (e.add_review.length > 0))
          // const review = value.filter
          for (var i = 0; i < value.length; i++) {
            for (var k = 0; k < value[i].add_review.length; k++) {
              if (value[i].add_review[k].assign_user == this.level.uidnumber && value[i].add_review[k].aprove_status == 'pending') {
                this.Review.push(value[i].add_review[k])
              }
            }
          }
        }
      })
  }

  getcategories() {
    this.hidetextCat = true;
    this._id = 'root'
    let url = this.api.baseAPIPath + '/categoryaudit/allcategories';
    this.router.navigate(['app/categorie/' + this._id], { queryParams: { name: this._id } })
    this.folderapi.getmethod('/categoryaudit/allcategories')
      .subscribe((data: any) => {
        this.getallcategories = data;
      
      })
  }
 
  allsubfolder = []
  allget = []
  _id: any
  templ(data) {
    this._id = data._id;
      this.router.navigate(['app/categorie/' + this._id], { queryParams: { name: this._id , pathName: data.folder_name } })
  }
  idbasedata(data, text) {
    this._id = data._id;
   
    if (text == 'Categories') {
      this.router.navigate(['app/categorie/' + this._id], { queryParams: { name: this._id , pathName: data.folder_name } })
    } else {
 
      this.currentPathArray.splice(0);
    
      this.currentPathArray.push({ name: data.Uploaded_filename, data: data, id: data._id });
    
      this.folderapi.currentpath$.next(this.currentPathArray)
    }
 
  }
  idbaseapplication(){
    this.router.navigate(['app/application'])
  }
  idbasedatat(data, text) {
   
    this._id = data._id;
    this.router.navigate(['app/template/' + this._id], { queryParams: { name: this._id, pathName: data.Uploaded_filename } });
  }
  // get login page data 
  userprofileimg
  userimage
  getuserdata() {
    let url = this.api.baseAPIPath + `/userlogin/userdata`
    let obj: any = {
      internluser:true,
      _id: this.user_id,
    }
    this.folderapi.postMethod(`/userlogin/userdata`, obj)
      .subscribe((data: any) => {
        this.user = data.data
        this.userimage = data.data.user_image
        if(data && data.data && this.userimage){
          this.userprofileimg = environment.buildurl+`/profile/${this.userimage}`
          }else{
            // this.userprofileimg = environment.apiUrl+`/profile/placeholder.jpg?token=${this.folderapi.login_userID.token}`
            this.userprofileimg = environment.buildurl+`/defaultprofile/placeholder.jpg`
          
          }
      })
  }

  // show hide card on click
  showcardsessition() {
    this.opencard = true
  }
  //text = root
  stepdata = 'true';
  upDown() {
    this.hidetext = !this.hidetext;
    this.getalldocument();
  }
  navigateroot(){
    this._id = 'root';
    this.router.navigate(['app/explorer/' + this._id], { queryParams: { name: this._id } });
    // this.currentPathArray=[]
   
    // this.folderapi.currentpath$.next(this.currentPathArray)
  }
  getalldocument() {
    this._id = 'root';
  
    if (this.level.User_type == "SuperAdmin") {
      let url = this.api.baseAPIPath + '/admin/allfolder'
      let obj: any = {
        parent: this._id,
      }
      this.router.navigate(['app/explorer/' + this._id], { queryParams: { name: this._id } });
      this.folderapi.postMethod('/admin/allfolder', obj)
        .subscribe((data: any) => {
          this.alldocument = data;
        })
    } else {
      let url = this.api.baseAPIPath + '/admin/getalldocumentfolder'
      let obj: any = {
        parent: this._id,
      
        email:this.folderapi.level._id
      }
      this.router.navigate(['app/explorer/' + this._id], { queryParams: { name: this._id } })
      this.folderapi.postMethod('/admin/getalldocumentfolder', obj)
        .subscribe((data: any) => {
          const alldocument = data;

          this.alldocument = alldocument.folder

          // .filter(element => element.userPermission
          //   .some(subElement => subElement._id === this.localStorageUser._id)
          // )
          // .map(element => {
          //   let n = Object.assign({}, element, {'userPermission': element.userPermission.filter(
          //     subElement => subElement._id === this.localStorageUser._id
          //   )})
          //   return n;
          // })
        })
    }
  }
  getalldocumentapplication(){
  
    let url = this.api.baseAPIPath + '/admin/getfolderappication'
    // let obj: any = {
    //   parent: this._id,
    //   selectdeparament: this.level.selectdeparament
    // }

    this.folderapi.getmethod('/admin/getfolderappication')
    .subscribe((data: any) => {

      this.application_document = data
      
    
    })
  }
  getallTempdocument() {
    this.hidetextCatTemp = !this.hidetextCatTemp;
    this._id = 'root';
    if (this.level.User_type == "SuperAdmin") {
      let url = this.api.baseAPIPath + '/admin/allTempfolder'
      let obj: any = {
        parent: this._id,
      }
      this.router.navigate(['app/template/' + this._id], { queryParams: { name: this._id } });
      this.folderapi.postMethod('/admin/allTempfolder', obj)
        .subscribe((data: any) => {
          this.allTempdocument = data;
        })
    } else {
      let url = this.api.baseAPIPath + '/admin/getallTempfolder'
      let obj: any = {
        parent: this._id,
        selectdeparament: this.level.selectdeparament
      }
      this.router.navigate(['app/template/' + this._id], { queryParams: { name: this._id } })
      this.folderapi.postMethod('/admin/getallTempfolder', obj)
        .subscribe((data: any) => {
          const alldocument = data
          this.allTempdocument = alldocument
        })
    }
  }
  step: any
  gotopage(page) {
    const data = page;
    const obj = {
      data:page,
      updateReceivedData:false,
      _id: this.user_id
    }
    this.shareservice.updateData(obj);
    this.api.postMethod('/userlogin/logout', obj)
    .subscribe((data: any) => {console.log(data)})
    this.step = page
    if (page == "login") {
      localStorage.clear();
      this.router.navigate(['auth/' + page])
      document.getElementById("sidebar").classList.remove('sidebar-open')
      document.getElementById("sidebar").classList.remove('sidebar-collapse')
    } else {
    
      this.router.navigate(['app/' + page])
    
      document.getElementById("sidebar").classList.remove('sidebar-open')
      document.getElementById("sidebar").classList.remove('sidebar-collapse')
    }
  }

  gotonotification(){
    this.router.navigate(['app/notification'])
  }


  gotouserstorage(){
   
    if(this.folderapi.login_userID.User_type=='SuperAdmin'){
 
      this.router.navigate(['/app/admin_storage'])
    }
   
  }

  // @ngAfterViewInit(){
  //   this.paramdata = params
  //   if(params['q']){
  // this.searchvalue.nativeElement.value = params['q']
  //   }
  // }

  // go to my mail account 
  gotomailpage(page) {
    $("#myDiv").removeAttr("style");
    if (page == "login") {
      localStorage.clear();
      this.router.navigate(['app/' + page])
    } else {
      this.router.navigate(['app/' + page])
    }
  }
  // removeclass() {
  //   setTimeout(function () {
  //     $("#myDiv").removeAttr("style");
  //   }, 1000);

  // }
  openfoldermodel() {
    this.addfoldername = '';
    $('.modal').on('shown.bs.modal', function() {
      $(this).find('[autofocus]').focus();
    });
    $('#foldermodal').modal('show');
  }

  getallcategories = []
  addnewfolder() {
    if (this.addfoldername) {
      let url = this.api.baseAPIPath + '/categoryaudit/addcategories'
      let obj = {
        folder_name: this.addfoldername
      }
      this.folderapi.postMethod('/categoryaudit/addcategories', obj)
        .subscribe((data: any) => {
        
          this.getallcategories.push(data)
          $('#foldermodal').modal('hide');
          this.toastr.success("your folder name added successfully!")
        })
    } else {
      this.toastr.error('folder name required!')
    }
  }
  gotoshare(){
    this.router.navigate(['app/shared-document/']);
    //  { queryParams: { name: this._id } }
  }
  // ###### ----------------- end ---------------- #####
  getmodulelist(){
  
    let url 
    let obj={}
  // getsubmodulelist
    url = environment.apiBaseURL + '/usermanagmentdocument/getadminmodulelist'

    this.folderapi.postMethod('/usermanagmentdocument/getadminmodulelist', obj)
    .subscribe((data: any) => {
    
      let module_list = data
   
    this.module_list =   module_list

  

  

    })
  
  }
  getusermodulelist(){
  let obj={
      userID:this.localStorageUser.Asign_storage.user_name
          }
        let   url = environment.apiBaseURL + '/usermanagmentdocument/getusermodulelist'
        this.folderapi.postMethod('/usermanagmentdocument/getusermodulelist', obj)
        .subscribe((data: any) => {
          
          let module_list = data
       
        this.module_list =   module_list.modulelist
     this.folderapi.folderlist = module_list.folderdata
     this.folderapi.filelist = module_list.filedata

   
        })

      }

  checkedparent(data){
   
    if(data.expandable=='false'){
      if(data.queryparam){
        this.router.navigate([data.route + data.queryparam], { queryParams: { name: data.queryparam } })
        if(data.identifier=='folder'){

        }else if(data.identifier=='categories'){
        this.getcategories()
        }else if(data.identifier=='templates'){
this.getallTempdocument()
        }
      }else{
       
        if(data.route!="#"){
          this.router.navigate([data.route])
        }
      
      }
    }else if(data.expandable=='true'){
     
      data.selcte = true
      if(data.queryparam){
        this.router.navigate([data.route + data.queryparam], { queryParams: { name: data.queryparam } })
        if(data.identifier=='folder'){

        }else if(data.identifier=='categories'){
        this.getcategories()
        }else if(data.identifier=='templates'){
this.getallTempdocument()
        }
      }else{
        if(data.route!="#"){
        this.router.navigate([data.route])
        }
      }
    
    }
  }
 
  subparentchange(data){
    if(data.isChecked==false){
      data.isChecked= true
    }else{
      data.isChecked= false
    }
  }
  usereditfolder(){
    let url = environment.apiBaseURL + '/usermanagmentdocument/getuserfolderlist'
  let obj={

  }
 
this.folderapi.postMethod('/usermanagmentdocument/getuserfolderlist',obj).subscribe((res:any)=>{
let allbase = res

this.folderapi.editfolderlist = [...allbase.editfolderlist]
this.folderapi.readfolderlist = [...allbase.folderlist]
this.folderapi.editfilelist = [...allbase.editfilelist]
this.folderapi.readfilelist = [...allbase.readfilelist]
this.folderapi.fullfilelist = [...allbase.fullfilelist]
this.folderapi.fullfolderlist = [...allbase.fullfolderlist]
// fullfolderlist
// fullfilelist 
})
  }
  gotonotificationpage(data){
    this.update_notification_status(data)
    window.location.href =  data.url_link
  
  }

  update_notification_status(data){
    let obj ={
      notification_id : data._id,
      usertype: this.folderapi.login_userID.User_type
    }
    this.folderapi.postMethod('/notification_action/update_notification_read',obj).subscribe((res:any)=>{
  
   
  
    })
  }
}
