import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http'
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common'
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http'
import { DDMS } from "../provider/DDMS"

import { PagesComponent } from './layouts/pages/pages.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './auth.guard';
import { FooterpageComponent } from "./sheard/footer/footer.component";
import { SidebarComponent } from "./sheard/sidebar/sidebar.component";
import { TestComponent } from './sheard/test/test.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { LogindomainComponent } from './logindomain/logindomain.component';
import { NavbarModule } from './sheard/navbar/navbar.module';
import { ApplicationDocumentComponent } from './application-document/application-document.component';
import { MonitorInterceptor } from './monitor.interceptor';
import { PaginatePipe } from './paginate.pipe';
import { PaginationControllerComponent } from './pagination-controller/pagination-controller.component';
// import { PaginationComponent } from './pagination/pagination.component';


// import { ErrorInterceptor } from './error.interceptor';
// import { EditorComponent } from './editor/editor.component';

// import { VendorListComponent } from './dashbord/vendor-list/vendor-list.component';
// import { DocumentEditorModule } from '@txtextcontrol/tx-ng-document-editor';


// import { SatPopoverModule } from '@ncstate/sat-popover';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    AuthComponent,
    TestComponent,
    FooterpageComponent,
    SidebarComponent,
    
    LogindomainComponent,
    
    ApplicationDocumentComponent,
          PaginationControllerComponent,
          // PaginationComponent,
        
          // PaginatePipe,
       
        //  EditorComponent,
    // VendorListComponent
  ],
  imports: [
    // SatPopoverModule,
    HttpModule,
    NgxDocViewerModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule ,  
    NavbarModule,
    // SweetAlert,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
  ],
  exports:[],
  providers: [DDMS, AuthGuard,  {
    provide: HTTP_INTERCEPTORS,
    useClass: MonitorInterceptor,
    multi: true
  }, 

],
  bootstrap: [AppComponent]
})
export class AppModule { }
