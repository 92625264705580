
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarpageComponent } from './navbar.component';
import { FormsModule } from '@angular/forms';
import { FilesizePipe } from './filesize.pipe'
import { StorageRequestComponent } from './storage-request/storage-request.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//import { SidebarModule} from '../sidebar/sidebar.module';


@NgModule({
  imports: [RouterModule, CommonModule, FormsModule ,ToastrModule.forRoot()],
  declarations: [NavbarpageComponent,FilesizePipe,StorageRequestComponent],
  exports: [NavbarpageComponent,FilesizePipe]
})

export class NavbarModule {  
}
