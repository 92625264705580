import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination-controller',
  templateUrl: './pagination-controller.component.html',
  styleUrls: ['./pagination-controller.component.css']
})
export class PaginationControllerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
