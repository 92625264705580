import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
 // @ViewChild('navbar') navbar: any;
  constructor() { }

  ngOnInit() {
  }

}
