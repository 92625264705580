import { Component, OnInit, Output } from '@angular/core';
import { Injectable, EventEmitter } from "@angular/core";
import { environment } from '../environments/environment'
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Router, } from "@angular/router";
const helper = new JwtHelperService();
@Injectable({
    providedIn: 'root'
})
export class ApiServiceService  {
  
  public baseAPIPath = environment.apiBaseURL;
  public fileServerUrl = environment.apiBaseURL; 
  href : any;
  preViewData: any;
  forShare = false
  toke: boolean;
  requestloader = false
  @Output() added = new EventEmitter<boolean>();
  constructor(private http: HttpClient,private router: Router,) {
     this.href = window.location.href;
    }
    myPreviewData(data) {
     
      let Parent_d = 'root';
      this.preViewData = data;
      this.added.emit(this.preViewData);
      this.forShare = true;
      if(this.forShare === true) {
        console.log("service call")
        this.router.navigate(['dashboard/explorer/' + Parent_d], { queryParams: { name: Parent_d } })
      }
    }

    tokenNotExpired() {
      let userdata
      let tokendata
      if(localStorage.getItem('USER')){
        userdata = JSON.parse(localStorage.getItem('USER'))
        tokendata = userdata.token
      }
  
      const token: string = tokendata;
    
  
      // token != null && !helper.isTokenExpired(token);
      this.toke= token!=null&&!helper.isTokenExpired(token)
      return this.toke
    }
    loggedin(){
     
      return this.tokenNotExpired();
    }


    // tokenexpired(){
    //   let userdata
    //   let tokendata
    //   if(localStorage.getItem('USER')){
    //     userdata = JSON.parse(localStorage.getItem('USER'))
    //     tokendata = userdata.token
    //   }
  
    //   const token: string = tokendata;
   
    // // helper.isTokenExpired(token)
    // let b = true
    // console.log(b)
    //   return  helper.isTokenExpired(token)
    // }
    Configuration = [

      { _id: "1", name: "Configuration" , page: "configuration" },
      { _id: "2", name: "LDAP Configuration", page: "Ldapconfiguration" },
      { _id: "3", name: " Upload Company Logo ", page: "companylogopage" },
      { _id: "4", name: "Change Wallpaper", page: "wallpaper" },
      { _id: "5", name: "Folder Watcher", page: "folderwatcher" },
      // { _id: "6", name: "Settings", page: "" },
    ]
    User = [
      { _id: "1", name: "User Summary", page: "user" },
      { _id: "2", name: "Logged Users", page: "LoggedUser" },
      { _id: "3", name: "  User Profile ", page: "userprofile" },
      // { _id: "4", name: "Roles", page: "managerole" },
      // { _id: "5", name: "Roles Mapping ", page: "role-mapping" },
      // { _id: "6", name: "Profile mapping", page: "profile-mapping" },
      { _id: "7", name: "User Authorization Request", page: "user-authorization" },
    ]
    activation = [
      { _id: "1", name: "License Generation", page: "license" },
      { _id: "2", name: "Manage License", page: "manage_license" },
      // { _id: "3", name: "Manage notifaction ", page: "" }
    ]
    metadata = [
      { _id: "1", name: "Metadata Summary", page: "metadatasummary" },
      { _id: "2", name: "Folder Mappings", page: "getfoldermappings" },
      // { _id: "3", name: "File Type Mappings" },
      // { _id: "4", name: "Meta Data File Upload" },
      // { _id: "5", name: "Metadata XML Mapping" }
    ]
    workflow = [
      { _id: "6", name: "Add Department", page: "department" },
      // { _id: "7", name: "Add Designation", page: "designation" },
      // { _id: "8", name: "Add Verfication", page: "verfication" },
    ]
    report = [
      { _id: "1", name: "Login details ", page: "loguser" },
      { _id: "2", name: "Document stage ", page: "document_stage" },
      { _id: "4", name: "Document  Activities", page: "document_activitie" },
      { _id: "4", name: "Disk  Management", page: "management" },
    ];
    fileoperation = [
      { id: "1", name: "Download", text: "download" ,type:"download", value:true},
      { id: "2", name: "Request", text: "request",type:"request", value:true },
      { id: "3", name: "Move", text: "copy" ,type:'move', value:true},
      { id: "6", name: "Assign", text: "assign" , type:'assign', value:true},
      { id: "4", name: "Rename", text: "Rename", type:'rename' , value:true},
      { id: "5", name: "Delete", text: "Delete" , type:'delete', value:true},
      { id : "7", name: "Copy", text:"text", type:'copy', value:true}
      // { id: "6", name: "Export to zip", text: "zip" },
    ]
    properties = [
      { id: "1", name: "Notes", text: "notes" },
      { id: "1", name: "Securities", text: "securities" },
      { id: "1", name: "Notifications", text: "notifications" },
      { id: "1", name: "Alert", text: "alert" },
      { id: "1", name: "Auditing", text: "auditing" }
    ]
    folderoperation = [
      { id: '1', name: "Copy", text: "copy" },
      { id: '2', name: "Rename", text: "rename" },
      { id: '3', name: "Delete", text: "delete" },
      { id: '3', name: "Export to ZIP", text: "zip" },
    ]
  
    Notification = [
      { id: '1', name: "Alerts", text: "alerts" },
      { id: '2', name: "Auditing", text: "auditing" },
      { id: '3', name: "Add Subscription", text: "subscription" },
    ]
  
    documentedit = [
      { id: "1", name: "Edit", text: "edit" , type:"edit", value:true},
      { id: "2", name: " Lock", text: "lock" , type:"Lock", value:true},
      { id: "3", name: "Update new version", text: "newversion" , type:"Update_new_version", value:true},
      { id: "4", name: "Pdf split", text: "pfdsplit" , type:"pfdsplit", value:true},
    ]
  
    view = [
      { id: "1", name: "Properties", text: "properties" ,type:'properties', value:true},
      { id: "2", name: "Workflow Log", text: "log", type:'Workflow_log' , value:true},
      { id: "3", name: "Audit Log", text: "audit" ,type:'Audit_log', value:true},
      { id: "4", name: "History", text: "history" , type:'History', value:true},
      { id: "5", name: "Notes", text: "notes", type:'notes'},
      { id: "6", name: "Security", text: "security" , type:'security', value:true},
    ]

    shear = [
      { id: "1", name: "Download Document", text: "download" },
      { id: "2", name: "Share link", text: "link" },
      { id: "3", name: "Send as Attachment", text: "Attachment" },
      { id: "4", name: "Send document link-external", text: "external" }
    ]
  
    documentmanagement = [
      { id: "1", name: "Create link", text: "link" , type:'Create_link', value:true},
      { id: "2", name: " Document expiration", text: "expiration" , type:'expiration', value:true},
    ]
    getMethod(apiname) {
      return this.http.get(this.baseAPIPath + apiname);
    }
  getMethodSdk(apiname) {
      return this.http.get(this.fileServerUrl + apiname);
    }
  getMethodFile() {
    return this.http.get(this.fileServerUrl + '/total_files');
  }
  deletedata(urlt: any, id: any)  {
    const deleteUrl = this.baseAPIPath + urlt + '/' + id;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.delete(deleteUrl);
  }
    postMethod(apiname,data){
      const updateUrl = this.baseAPIPath + apiname;
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      return this.http.post(updateUrl, data);
    }
  
  
    postMethodFile(apiname,data){
      const updateUrl = this.fileServerUrl + apiname;
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      return this.http.post(updateUrl, data);
    }
    patchMethodFile(apiname,data){
      const updateUrl = this.fileServerUrl + apiname;
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      return this.http.patch(updateUrl, data);
    }
  

}