import { HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// import axios from 'axios';
import { environment } from 'src/environments/environment';
import { SafeResourceUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class FolderService {
  fileSizeUnit: number = 1024;
  login_userID: any;
  _id: any;
  user_name: any;
  level: any
  previewfileopen = false
  rightClickMenuItems = [];
  currentpath$ = new Subject<any[]>();
  contextmenu = new BehaviorSubject(0);
  currenttemplatepath$ = new Subject<any[]>();
  currentapplicationpath$= new Subject<any[]>();
  managersubject$ = new Subject();
  permissionuser = false
  latestpath$ = new Subject()
  folderlist: any
  filelist: any
  fullfilelist = []
  fullfolderlist = []
  wopiurl: any
  // observer = new Subject();
  // public filepath$ = this.observer.asObservable();
  filepath$ = new Subject<any>()

  logprofile: any;
  departement: any;
  departement1 = [];
  pdffile = false
  docfile = false
  emply = false
  currentfilepath = []
  editfolderlist = []
  readfolderlist = []
  editfilelist = []
  readfilelist = []
  userpermissioncheck = false
  shareddata: any;
  settingdata: any;
  storage = 0
  remaining_storage= 0
  usedstorage=0
  percentage=0
  limitofstorage = 0
  redalert = false
  storage_active = false


  constructor( private http: HttpClient) {

    this.getuserdata()



  }

  changeDataSubject(data: any) {
  
    this.contextmenu.next(data);
  }

  ngOnInit() {
    this.login_userID = JSON.parse(localStorage.getItem("USER"));
   

  }
  getrightmenufolder(data) {

    let editoruser = data.allowed_users.map(el => el._id).includes(this.login_userID._id)
    let readuser
    if (!editoruser) {
      readuser = data.allowed_read_users.map(el => el._id).includes(this.login_userID._id)
    }
   
    if (this.login_userID.User_type == 'SuperAdmin' || data.owner_email._id == this.login_userID._id) {
      this.rightClickMenuItems = [
        {
          menuText: '<i class="fas fa-folder-plus text-warning pointer" aria-hidden="true"></i> Add Folder',
          menuEvent: 'Add Folder',
        },
        {
          menuText: '<i class="fa fa-file text-muted pointer" aria-hidden="true"></i> Add Document',
          menuEvent: 'Add Document',
        },
        {
          menuText: '<i class="fa fa-file text-muted pointer" aria-hidden="true"></i> Manage Access',
          menuEvent: 'Manage Access',
        },

        {
          menuText: '<i class="fa fa-edit text-muted pointer" aria-hidden="true"></i> Rename',
          menuEvent: 'Rename',
        },
        {
          menuText: '<i class="fa fa-arrows text-muted pointer" aria-hidden="true"></i> Move',
          menuEvent: 'Move',
        },
        {
          menuText: '<i class="fa fa-copy text-muted pointer" aria-hidden="true"></i> Copy',
          menuEvent: 'Copy',
        },
        {
          menuText: '<i class="fa fa-paste text-muted pointer" aria-hidden="true"></i>Paste',
          menuEvent: 'Paste',
        },
        {
          menuText: '<i class="fa fa-trash text-muted pointer" aria-hidden="true"></i> Remove',
          menuEvent: 'Remove',
        },
        // {
        //   menuText: '<i class="fa fa-lock text-muted pointer" aria-hidden="true"></i> Allow Permission',
        //   menuEvent: 'Allow Permission',
        // },
        // {
        //   menuText: '<i class="fa fa-file-archive text-muted pointer" aria-hidden="true"></i> Export Zip',
        //   menuEvent: 'Export Zip',
        // },
        // {
        //   menuText: '<i class="fa fa-file-archive text-muted pointer" aria-hidden="true"></i> Import Zip',
        //   menuEvent: 'Import Zip',
        // },
        {
          menuText: '<i class="fa fa-info-circle text-muted pointer" aria-hidden="true"></i> Properties',
          menuEvent: 'Properties',
        },


      ];
    }
    else if (editoruser) {
      this.rightClickMenuItems = [
        {
          menuText: '<i class="fas fa-folder-plus text-warning pointer" aria-hidden="true"></i> Add Folder',
          menuEvent: 'Add Folder',
        },
        {
          menuText: '<i class="fa fa-file text-muted pointer" aria-hidden="true"></i> Add Document',
          menuEvent: 'Add Document',
        },
        {
          menuText: '<i class="fa fa-edit text-muted pointer" aria-hidden="true"></i> Rename',
          menuEvent: 'Rename',
        },
        {
          menuText: '<i class="fa fa-copy text-muted pointer" aria-hidden="true"></i> Copy',
          menuEvent: ' Copy',
        },
        {
          menuText: '<i class="fa fa-paste text-muted pointer" aria-hidden="true"></i>Paste',
          menuEvent: 'Paste',
        },
        {
          menuText: '<i class="fa fa-info-circle text-muted pointer" aria-hidden="true"></i> Properties',
          menuEvent: 'Properties',
        },


      ];
    }

    else if (readuser) {
      this.rightClickMenuItems = [

        {
          menuText: '<i class="fa fa-info-circle text-muted pointer" aria-hidden="true"></i> Properties',
          menuEvent: 'Properties',
        },
      ];
    }
    return this.rightClickMenuItems
  }


  isDocumentLocked(documentId: string): Observable<boolean> {
    // //documentotp/documents/:documentId/isLocked
    const updateUrl = environment.apiBaseURL + `/documentotp/documents/${documentId}/isLocked`;
   
  
   return this.http.get<boolean>(updateUrl);

  }

  errormail(data){
    const updateUrl = environment.apiBaseURL + '/admin/getmail';
   
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
  
    return this.http.post(updateUrl, data);
  }

  
  // getmenuclickitem
  // getrightmenufolder

  // getmenuontest
  getmenuontest(data) {
    let rightclickmenu = []
    let editoruser = data.allowed_users.map(el => el.userid && el.userid._id ? el.userid._id : el.userid).indexOf(this.login_userID._id)
    let readuser


    readuser = data.allowed_groupanddepartment_users.map(el => el.department && el.department._id ? el.department._id : el.department).indexOf(this.login_userID.selectdeparament)




    if (this.login_userID.User_type == 'SuperAdmin') {
      rightclickmenu = [...this.fullfolderlist]
    }
    else if (this.login_userID.Asign_storage.user_name) {

      if (!this.folderlist.allfolderacess) {
        rightclickmenu = [...this.folderlist.allfolderlist.filter(el => el.flag == "1")]
      } else {
        rightclickmenu = [...this.fullfolderlist]
      }


      if (editoruser != -1 && data.allowed_users[editoruser].edit == true) {

        rightclickmenu = []
        rightclickmenu = [...this.editfolderlist]

      } else {
        rightclickmenu = []
        rightclickmenu = [...this.readfolderlist]
      }

      if (readuser != -1 && data.allowed_groupanddepartment_users[readuser].edit == true) {

        rightclickmenu = []
        rightclickmenu = [...this.editfolderlist]
      } else {
        rightclickmenu = []
        rightclickmenu = [...this.readfolderlist]
      }




    } else {
      if (readuser != -1 && data.allowed_groupanddepartment_users[readuser].edit == true) {
        //  readuser&&readuser.edit==true
        rightclickmenu = [...this.editfolderlist]

      } else if (readuser != -1 && data.allowed_groupanddepartment_users[readuser].read == true) {
        rightclickmenu = [...this.readfolderlist]
      }

      else if (editoruser != -1 && data.allowed_users[editoruser].edit == true) {
        rightclickmenu = [...this.editfolderlist]
      } else if (editoruser != -1 && data.allowed_users[editoruser].read == true) {
        rightclickmenu = [...this.readfolderlist]
      }

    }
    return rightclickmenu

  }
  getmenuclickitem(data) {
   
    let rightclickmenu = []
    let readuser
    
    if(data.allowed_groupanddepartment_users){
      readuser =    data.allowed_groupanddepartment_users.map(el => el.department && el.department._id ? el.department._id : el.department).indexOf(this.login_userID.selectdeparament)
    }
  


    if (this.login_userID.User_type == 'SuperAdmin') {
      rightclickmenu = [...this.fullfolderlist]
    } else if (this.login_userID._id == data.owner_email || this.login_userID._id == data.owner_email._id) {
   
      rightclickmenu = [...this.folderlist.allfolderlist.filter(el => el.flag == "1")]
    } else if (readuser != -1) {

      let value = data.allowed_groupanddepartment_users[readuser]
      if (value.edit) {
        rightclickmenu = []
        rightclickmenu = [...this.editfolderlist]

      } else {
       
        rightclickmenu = []
        rightclickmenu = [...this.readfolderlist]
      }

    } else {
      //  ["members_write", "members_read", "public_write", "public_read"]
      let editoruser = data.allowed_users.map(el => el.userid && el.userid._id ? el.userid._id : el.userid).indexOf(this.login_userID._id)
      if (editoruser != -1||data.access_mode=='members_write'||data.access_mode=='public_write') {
      
        let value = data.allowed_users[editoruser]
      
        if ((value&&value.edit) ||data.access_mode=='members_write'||data.access_mode=='public_write') {
        
          rightclickmenu = []
          rightclickmenu = [...this.editfolderlist]
        } else {
          rightclickmenu = []
          rightclickmenu = [...this.readfolderlist]
        }

      } else {
       
        rightclickmenu = []
        rightclickmenu = [...this.readfolderlist]
      }
    }








    return rightclickmenu
  }

  getcheckfilemenug(data, sharedata, settingdata) {








    let rightclickmenu = []
    let editoruser = data.allowed_users.map(el => el.userid && el.userid._id ? el.userid._id : el.userid).indexOf(this.login_userID._id)
    let readuser

    readuser = data.allowed_groupanddepartment_users.map(el => el.department && el.department._id ? el.department._id : el.department).indexOf(this.login_userID.selectdeparament)


    if (this.login_userID.User_type == 'SuperAdmin') {
      rightclickmenu = [...this.fullfilelist]

    }
    else if (this.login_userID.Asign_storage.user_name) {

      if (!this.filelist.allfileacess) {

        rightclickmenu = [...this.filelist.allfilelist]
      } else {

        rightclickmenu = [...this.fullfilelist]
      }



      if (editoruser != -1 && data.allowed_users[editoruser].edit == true) {

        rightclickmenu = []
        rightclickmenu = [...this.editfilelist]

      } else {
        rightclickmenu = []
        rightclickmenu = [...this.readfilelist]
      }

      if (readuser != -1 && data.allowed_groupanddepartment_users[readuser].edit == true) {

        rightclickmenu = []
        rightclickmenu = [...this.editfilelist]

      } else {
        rightclickmenu = []
        rightclickmenu = [...this.readfilelist]
      }


      if (sharedata && sharedata.settingstatus == 1) {
        rightclickmenu = [...settingdata.permission_file_array]
      }



    } else {

      if (readuser != -1 && data.allowed_groupanddepartment_users[readuser].edit == true) {
        //  readuser
        rightclickmenu = [...this.editfilelist]

      } else if (readuser != -1 && data.allowed_groupanddepartment_users[readuser].read == true) {
        rightclickmenu = [...this.readfilelist]
      }
      else if (editoruser != -1 && data.allowed_users[editoruser].edit == true) {
        // editoruser
        rightclickmenu = [...this.editfilelist]

      }
      else if (editoruser != -1 && data.allowed_users[editoruser].read == true) {
        rightclickmenu = [...this.readfilelist]
      }
      if (sharedata && sharedata.settingstatus == 1) {
        rightclickmenu = [...settingdata.permission_file_array]
      }
    }
    
    return rightclickmenu
  }
  // getcheckfilemenu
  getmenuclickitemfile(data, sharedata, settingdata) {
    let rightclickmenu = []
    let removeindex
    let readuser
    if(data.allowed_groupanddepartment_users){
      readuser =    data.allowed_groupanddepartment_users.map(el => el.department && el.department._id ? el.department._id : el.department).indexOf(this.login_userID.selectdeparament)
    }
  
    if (this.login_userID.User_type == 'SuperAdmin') {
      rightclickmenu = [...this.fullfilelist]
    } else if (this.login_userID._id == data.owner_email || this.login_userID._id == data.owner_email._id) {
    
      rightclickmenu = [...this.filelist.allfilelist.filter(el => el.flag == "1")]
    } else if (readuser&&readuser != -1) {
   
      if(data.allowed_groupanddepartment_users){
        let value = data.allowed_groupanddepartment_users[readuser]
        if (value.edit) {
          rightclickmenu = []
          rightclickmenu = [...this.editfilelist]
  
        } else {
          rightclickmenu = []
          rightclickmenu = [...this.readfilelist]
        }
      }else{
        rightclickmenu = []
          rightclickmenu = [...this.readfilelist]
      }
    

    } else if (sharedata && sharedata.settingstatus == 1) {
      rightclickmenu = [...settingdata.permission_file_array]
    }
    else {
    
      let editoruser
      if(data.allowed_users){
        editoruser = data.allowed_users.map(el => el.userid && el.userid._id ? el.userid._id : el.userid).indexOf(this.login_userID._id)
      }
     
      if ( data.access_mode=='members_write'||data.access_mode=='public_write'||editoruser!= -1) {
        let value = data.allowed_users[editoruser]
       
        if ((value&&value.edit)||data.access_mode=='members_write'||data.access_mode=='public_write') {
          rightclickmenu = []
          rightclickmenu = [...this.editfilelist]
        } else {
          rightclickmenu = []
          rightclickmenu = [...this.readfilelist]
        }

      } else {
        rightclickmenu = []
        rightclickmenu = [...this.readfilelist]
      }
    }
    if (data.lock && (this.login_userID.User_type == 'SuperAdmin'||this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)) {
    
      removeindex = rightclickmenu.map((el) => el.identifier).indexOf('lock')
      rightclickmenu.splice(removeindex, 1)
    }
    if (!data.lock  && (this.login_userID.User_type == 'SuperAdmin' || this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)) {
     
      removeindex = rightclickmenu.map((el) => el.identifier).indexOf('unlock')
     if(removeindex!=-1){
      rightclickmenu.splice(removeindex, 1)
     }
     
    }

    // if((data.documents_access_detail && data.documents_access_detail[0] && data.documents_access_detail[0].lock) && (this.login_userID.User_type == 'SuperAdmin'||this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)){
    //   removeindex = rightclickmenu.map((el) => el.identifier).indexOf('lock')
    //   rightclickmenu.splice(removeindex, 1)
    // }

    // if((data.documents_access_detail && data.documents_access_detail[0] && !data.documents_access_detail[0].lock) && (this.login_userID.User_type == 'SuperAdmin'||this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)){
    //   removeindex = rightclickmenu.map((el) => el.identifier).indexOf('unlock')
    //   rightclickmenu.splice(removeindex, 1)
    // }
   
    return rightclickmenu
  }


  getmenuclickitemfiledoc(data, sharedata, settingdata) {
    let rightclickmenu = []
    let removeindex
    let readuser
    if(data.allowed_groupanddepartment_users){
      readuser =    data.allowed_groupanddepartment_users.map(el => el.department && el.department._id ? el.department._id : el.department).indexOf(this.login_userID.selectdeparament)
    }
  
    if (this.login_userID.User_type == 'SuperAdmin') {
      rightclickmenu = [...this.fullfilelist]
    } else if (this.login_userID._id == data.owner_email || this.login_userID._id == data.owner_email._id) {
      rightclickmenu = [...this.filelist.allfilelist.filter(el => el.flag == "1")]
    } else if (readuser&&readuser != -1) {
   
      if(data.allowed_groupanddepartment_users){
        let value = data.allowed_groupanddepartment_users[readuser]
        if (value.edit) {
          rightclickmenu = []
          rightclickmenu = [...this.editfilelist]
  
        } else {
          rightclickmenu = []
          rightclickmenu = [...this.readfilelist]
        }
      }else{
        rightclickmenu = []
          rightclickmenu = [...this.readfilelist]
      }
    

    } else if (sharedata && sharedata.settingstatus == 1) {
      rightclickmenu = [...settingdata.permission_file_array]
    }
    else {
    
      let editoruser
      if(data.allowed_users){
        editoruser = data.allowed_users.map(el => el.userid && el.userid._id ? el.userid._id : el.userid).indexOf(this.login_userID._id)
      }
     
      if ( data.access_mode=='members_write'||data.access_mode=='public_write'||editoruser!= -1) {
        let value = data.allowed_users[editoruser]
       
        if ((value&&value.edit)||data.access_mode=='members_write'||data.access_mode=='public_write') {
          rightclickmenu = []
          rightclickmenu = [...this.editfilelist]
        } else {
          rightclickmenu = []
          rightclickmenu = [...this.readfilelist]
        }

      } else {
        rightclickmenu = []
        rightclickmenu = [...this.readfilelist]
      }
    }
    // if (data.lock && (this.login_userID.User_type == 'SuperAdmin'||this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)) {
    //   removeindex = rightclickmenu.map((el) => el.identifier).indexOf('lock')
    //   rightclickmenu.splice(removeindex, 1)
    // }
    // if (!data.lock  && (this.login_userID.User_type == 'SuperAdmin' || this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)) {
    //   removeindex = rightclickmenu.map((el) => el.identifier).indexOf('unlock')
    //   rightclickmenu.splice(removeindex, 1)
    // }

    if((data.documents_access_detail && data.documents_access_detail[0] && data.documents_access_detail[0].lock) && (this.login_userID.User_type == 'SuperAdmin'||this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)){
      removeindex = rightclickmenu.map((el) => el.identifier).indexOf('lock')
      rightclickmenu.splice(removeindex, 1)
    }

    if((data.documents_access_detail && data.documents_access_detail[0] && !data.documents_access_detail[0].lock) && (this.login_userID.User_type == 'SuperAdmin'||this.login_userID._id == data.owner_email._id||this.login_userID._id == data.owner_email)){
      removeindex = rightclickmenu.map((el) => el.identifier).indexOf('unlock')
      rightclickmenu.splice(removeindex, 1)
    }

    return rightclickmenu
  }


  readfolderdata() {

  }

  // readdownloaddata(data,extention,dataoffile){
   
  //   axios({
  //     url: data,
  //     method: 'GET',
  //     responseType: 'blob', // Important for handling binary data
  //     headers: {
  //       'Authorization': `Bearer ${this.login_userID.token}`,
  //     },
  //   })
  //     .then(response => {
      
  //       // Use file-saver to save the file
  //       saveAs(data, dataoffile.file_uid);
  //     })
  //     .catch(error => {
  //       console.error('Error downloading file:', error);
  //     });
  // }


  // downloaddata(){
    
  // }
  getuserdata() {

    if (JSON.parse(localStorage.getItem("USER"))) {
      let login_user = JSON.parse(localStorage.getItem("USER"));
      this.login_userID = JSON.parse(localStorage.getItem("USER"));
      this._id = login_user._id
      this.user_name = login_user.name
      this.level = login_user
      if (this.login_userID.User_type == 'SuperAdmin') {
        this.permissionuser = true
      } else {
        this.userprofile()
      }

    }

  }




  userprofile() {

    let url = '/userprofile/userprofile'
    let obj = {
      userid:this.login_userID._id,
      _id: this.level.Asign_storage._id,
      selectdeparament: this.level.selectdeparament
    }
   
    this.postMethod(url, obj)
      .subscribe((data: any) => {
      
        let allbase = data
       

        this.userpermissioncheck = allbase.permission
     
        if (allbase.permission) {
          this.permissionuser = true
        }
        this.logprofile = allbase.result
        this.departement = allbase.data ? allbase.data : []

        const value = this.departement.filter((e) => e.user_name._id != this.level._id)
      this.storage_active = allbase.storage_active

        this.departement1 = value
        this.storage = allbase.storage
      
        this.remaining_storage = allbase.remaining_storage
     
        this.usedstorage = allbase.user_storage
     

       let   overallStorage =  this.storage
      this.percentage = (this.usedstorage / overallStorage) * 100
      // this.limitofstorage =this.storage - environment.limitstorage 
   
      if(this.percentage>=90){
     
        this.redalert = true
      }
// storage:gb,remaining_storage:total_remaining_storage,user_storage:usingdata
      },(err)=>{
        console.log(err,"on ingectro ")
      })
    
  }

  postMethod(apiname, data) {

    const updateUrl = environment.apiBaseURL + apiname;

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post(updateUrl, data);
  }

  generateThumbnail(filePath: Object) {
    const updateUrl =environment.apiBaseURL +'/files/thumbnail';
    let obj={
      filedata: filePath
    }
    return this.http.post<string>(updateUrl,obj);
  }
  postUploadMethod(apiname, data) {
    const updateUrl = environment.apiBaseURL + apiname;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post(updateUrl, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  postdownloadmethod(apiname,data){
    const updateUrl =environment.apiBaseURL + apiname;

    // const headers = new Headers();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers, responseType: 'blob' as 'json' };
    return this.http.get(updateUrl);
  }

  gettdownloadmethod(apiname,data){
    const updateUrl =environment.apiBaseURL + apiname;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers, responseType: 'blob' as 'json' };
    return this.http.post(updateUrl, data,options);
  }

  getmethod(apiname) {
    const updateUrl = environment.apiBaseURL + apiname;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.get(updateUrl);
  }

  getfilemodule(apiname){
    const updateUrl = environment.apiBaseURL + apiname;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.get(updateUrl);
  }

  getfoldermodule(apiname){
    const updateUrl = environment.apiBaseURL + apiname;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.get(updateUrl);
  }


  putMethod(apiname, data) {
    const updateUrl = environment.apiBaseURL + apiname;

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    
    // this.http.put(updateUrl, data).subscribe(el=>{
   
    // })
    return this.http.put(updateUrl, data);
  }
  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      }
    }

    return fileSizeInWords;
  }

  uploadMedia(formData: any) {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http
      .post(`http://yourapiurl`, formData, {

        // reportProgress: true,
        // observe: 'events',
        // headers,
      })
      .pipe(
        map((event) => {

          // switch (event.type) {

          //   // case HttpEventType.UploadProgress:
          //   //   const progress = Math.round((100 * event.loaded) / event.total);
          //   //   return { status: 'progress', message: progress };

          //   // case HttpEventType.Response:
          //   //   return event.body;
          //   default:
          //     return `Unhandled event: ${event.type}`;
          // }
        })
      );
  }
  // postupload()
  upload(url, formdata) {



    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(url, formdata, {

      reportProgress: true,
      observe: 'events',
      headers,
    }).pipe(map((event) => {
     
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round((100 * event.loaded) / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;


        default:
          return `Unhandled event: ${event.type}`;
      }
    }));
  }
  emailvalidation(value) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let validvalue = value.match(validRegex)
  
    return validvalue

  }

  async sharingdata(data) {
    let result
    let obj = {
      document_ID: data._id,
      loginuser: this.login_userID._id
    }
    // const promise = new Promise((resolve, reject) => {
    //   resolve(this.getdata(obj).toPromise());
    // });
    // promise.then(res=>{
    //   result = res
  
    // })

    return result

  }

  getdata(data) {
    let obj = {
      document_ID: data.document_ID,
      loginuser: this.login_userID._id
    }

    const promise = new Promise((resolve, reject) => {
      resolve(this.http.post(environment.apiBaseURL + '/usermanagmentdocument/getusersetting', obj).toPromise());
    });



    return promise
  }




  getdatas(data) {
    let obj = {
      document_ID: data._id,
      loginuser: this.login_userID._id
    }
    const promise = new Promise<void>((resolve, reject) => {
      const apiURL = environment.apiBaseURL + '/usermanagmentdocument/getusersetting';
      this.http.post(apiURL, obj).subscribe({
        next: (res: any) => {
         
          this.shareddata = res.sharedata
          this.settingdata = res.data

          resolve();
        },
        error: (err: any) => {
          reject(err);
        },
        complete: () => {
      
        },
      });
    });


    
    return promise;
  }




  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
        return `File "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `File "${file.name}" was completely uploaded!`;

      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

getawnerdetailofdocument(data){
 
  let doc = data[0]
let obj ={
  parnet:doc.id ,
  user:this.login_userID._id
}

 const promise = new Promise((resolve, reject) => {


  if(this.login_userID._id==doc.data.owner_email._id||this.login_userID.User_type=='SuperAdmin'||this.login_userID._id==doc.data.owner_email){
    if(doc.data.isFolder=='true'){
      resolve({permission:true})
    }else{
      resolve({permission:false})
    }

  }else{
  
    resolve(this.http.post(environment.apiBaseURL + '/admin/chackcurrentparentstatus', obj).toPromise());
  }
   
  });





  return promise

}







}
