import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './layouts/pages/pages.component';
import { AuthComponent } from './layouts/auth/auth.component';
// import { LogindomainComponent } from './logindomain/logindomain.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
},
{
    path: '',
    component: PagesComponent,
    children: [{
        path: 'app',
        loadChildren: () => import('./dashboard/pages.module').then(m => m.PagesModule)
    }]
},
{
    path: '',
    component: AuthComponent,
    children: [{
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    }]
},
{
path: '',
component: PagesComponent,
children: [{
    path: 'editor',
    loadChildren: () => import('./editor/pages.module').then(m => m.PagesModule)
}]
},
{
    path: '',
    component: PagesComponent,
    children: [{
        path: 'templateeditor',
        loadChildren: () => import('./template-editor/page.module').then(m => m.PageModule)
    }]
    },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

