import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Router, } from "@angular/router";
import { FolderService } from '../dashboard/explorer/folder/folder.service';
@Component({
  selector: 'app-logindomain',
  templateUrl: './logindomain.component.html',
  styleUrls: ['./logindomain.component.css']
})
export class LogindomainComponent implements OnInit {

  //environment = environment.apiBaseURL
  constructor(private http: Http, private router: Router, private toastr: ToastrService,private folderapi:FolderService ) { }
  emply = true
  usrname:any
  password:any
  website: any
  ngOnInit() {
  }


  Domainbase(){
    let domainname = window.location.hostname
    let url =  '/license/domainlogin'
    let obj :any ={
      username :this.usrname,
      password: this.password,
      website_name:this.website,
      domain_name: domainname 
    } 
   
    this.folderapi.postMethod(url,obj)
    .subscribe((data: any )=>{
      if(data.status == 200){
        this.router.navigateByUrl('auth/login')
  this.toastr.success("you have succefully login with this Domain name ")
      }  else{
        this.toastr.error("all credentials must be match then you can login with this domain name")
      }
    })
  }
}
