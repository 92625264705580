import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
  import { Router, } from "@angular/router";
  import { ApiServiceService } from "./api-service.service"
  declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DDMSfrontend';
  domain_name :any
  constructor(private http: Http,  private router: Router, public service : ApiServiceService  ) { }
  formatdate : any
 
  ngOnInit() {
    let data =  this.router.url;
   
      this.service.getMethod('/documentdateformet/getdateformat')
      .subscribe((data:any)=>{
      if(data)
       { this.formatdate = data.formatdate || 'dd/mm/yyyy'
        localStorage.setItem("dateformate", JSON.stringify(this.formatdate));}
      })
    
    if(localStorage.getItem("USER") !== null){
      //this.service.loginuser()
    } 

  }
}
