import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FolderService } from 'src/app/dashboard/explorer/folder/folder.service';

@Component({
  selector: 'app-storage-request',
  templateUrl: './storage-request.component.html',
  styleUrls: ['./storage-request.component.css']
})
export class StorageRequestComponent implements OnInit {
  @ViewChild('openstorageacess') openstorageacess: ElementRef;
  @ViewChild('closerequestmodel')closerequestmodel:ElementRef
  comment: any;
  loader= false
  constructor( private folderapi:FolderService) { }

  ngOnInit(): void {
  }

  openstorage(){
  this.openstorageacess.nativeElement.click()
  }

  notifyadmin(){
let obj ={
  userid : this.folderapi.login_userID._id,
  remaining_storage: this.folderapi.remaining_storage,
  totalstorage : this.folderapi.storage,
  used_storage : this.folderapi.usedstorage,
  comment:this.comment

}
this.loader = true 

this.folderapi.postMethod('/documentsize/requestsize',obj).subscribe(res=>{
  this.loader = false
  this.comment= ''
this.closedwork()
})


  }
  closedwork(){
    this.comment= ''
this.closerequestmodel.nativeElement.click()
  }
}
