import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ApiServiceService } from './api-service.service';
import { environment } from '../environments/environment'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { FolderService } from './dashboard/explorer/folder/folder.service';

// import { LoadingService } from '../services/loading.service';

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {
  public baseAPIPath = environment.apiBaseURL;
  constructor(private service:ApiServiceService ,public router:Router,public activatedgRoute: ActivatedRoute,private authService: AuthService) {}
  private totalRequests = 0;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    this.totalRequests++;
    let token 
    if(JSON.parse(localStorage.getItem('USER'))){
      token =   JSON.parse(localStorage.getItem('USER'))
    }

    const excludedUrls = [
      this.baseAPIPath+'/userlogin/login',
      this.baseAPIPath+'/dashboard/superadmindata',
      this.baseAPIPath+'/admin/superadmincall',
      this.baseAPIPath+'/admin/getalldocumentfolder',
      this.baseAPIPath+'/admin/navigationadmin',
      this.baseAPIPath+'/admin/navigationuser',
      this.baseAPIPath+'/dashboard/getfolder',
      this.baseAPIPath+'/documentaudit/approvedocument',
      this.baseAPIPath+'/documentaudit/approvealldocument',
      this.baseAPIPath+'/documentaudit/pendingapproval',
      this.baseAPIPath+'/documentaudit/pendingapproval',
      this.baseAPIPath+'/dashboard/filecountbase',
      this.baseAPIPath+'/dashboard/userfilecountbase',
    
    ];

    if(excludedUrls.includes(request.url)){

      this.service.requestloader = true
    }else{
      this.service.requestloader = false
    }
    const authRequest = token&&token.token
    ? request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.token}`,
        },
      })
    : request;

  
  
  
    const begin = performance.now();
    // console.log(authRequest,"authRequest")
    return next.handle(authRequest).pipe(
     
    
      finalize(() => {
        // const requestDuration = performance.now() - begin;
        // console.log(`Request for ${authRequest.urlWithParams} took ${requestDuration} ms.`);
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.service.requestloader = false
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const excludedUrls = [
          this.baseAPIPath+'/usermanagmentdocument/getusersetting',
          this.baseAPIPath+'/documentaudit/grouplist',
          this.baseAPIPath+'/categoryaudit/allcategories',
          this.baseAPIPath+'/admin/getallfile',
          this.baseAPIPath+'/documentsize/getfilesize'
    
        ];
  
        if (error.status === 403||error.status==401) {
          this.authService.autherror = true
    //  this.router.navigate(['/auth/login'])

        } else {
         

         
          if (excludedUrls.includes(request.url)) {
            const errorMessage = 'Error occurred: ' + error.message;
            console.error(errorMessage);
            // this.errorHandlingService.triggerErrorModal(errorMessage);
          } else if (request.url.includes('/show-modal-path')) {
         
            const errorMessage = 'Error occurred: ' + error.message;
            console.error(errorMessage);
          
          } else {
           if(error.status === 500){
  
//             let obj = {
//               api:authRequest.url,

//               companyuser: this.folderapi.login_userID.email,
//               time: new Date(),
//               payload:request.body,
//               status:error.status

//             }

// this.folderapi.errormail(obj)
           }else{
            console.error('An unexpected error occurred:', error);
           }

            
           
          }
          if (error.error instanceof ProgressEvent && error.error.type === 'timeout') {
            const timeoutErrorMessage = 'Connection timeout occurred';
            console.error(timeoutErrorMessage);
           
          }
        }

        // Re-throw the error so that it can be caught by the subscriber
        return throwError(error);
      })
    );
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    this.service.requestloader = true
    const requestDuration = `${performance.now() - startTime}`;
   
    setTimeout(()=>{
      this.service.requestloader = false
    }
     ,Number(requestDuration))
   
  }
}
