import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiServiceService } from '../api-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Http, Response } from '@angular/http';
import { FolderService } from '../dashboard/explorer/folder/folder.service';

@Component({
  selector: 'app-application-document',
  templateUrl: './application-document.component.html',
  styleUrls: ['./application-document.component.css']
})
export class ApplicationDocumentComponent implements OnInit {
  url: any
  src: any
  paramName: any;
  currentPathArray=[];
  alldocument: any;
  company_document:any
  application_document:any

  constructor( private activatedRoute: ActivatedRoute,public api: ApiServiceService,public folderapi: FolderService,private sanitizer: DomSanitizer,private http: Http) { }

  ngOnInit(): void {
    this.getalldocumentapplication()
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.paramName = params.pathName;
    //   let paramName2 = params.name;
    //   if (paramName2 !== 'root') {
    //     let url = '/admin/updatefolderGetapplication/' + paramName2;

    //     this.api.getMethod(url)
    //       .subscribe((data: any) => {
    //         this.currentPathArray.push({ name: this.paramName, _id: paramName2, data: data });

    //          this.alldocument = data;
   
          
    //       })
    //   }
    // });
  }

  showCompanyFolder=true
  showEmpCode=false
  showProductFolder=false
  showMonthFolder=false
  showFile=false

  getalldocumentapplication(){
    let url =  '/mydrive/getAllCompany'

    this.folderapi.getmethod(url)
    .subscribe((data: any) => {

      this.company_document = data
     
    
    })
  }

  allManNo;
  getAllManNo(){
    let url = this.api.baseAPIPath + '/mydrive/getAllManNo'

    this.http.get(url)
    .subscribe((data: any) => {

      this.allManNo = JSON.parse(data._body)
      if(this.allManNo.length>0){
        this.showCompanyFolder=false
        this.showEmpCode=true
        this.showProductFolder=false
        this.showMonthFolder=false
        this.showFile=false
      }
    
    })
  }

  // getManNo(man_no){
  //   let obj = {}
  //   obj['man_no']=man_no
  //   let url = this.api.baseAPIPath + '/application/getProduct'

  //   this.http.post(url,obj)
  //   .subscribe((data: any) => {
      
  //     this.application_document=JSON.parse(data._body)
  //     if(this.application_document.length>0){
  //       this.showCompanyFolder=false
  //       this.showProductFolder=true
  //       this.showMonthFolder=false
  //       this.showFile=false
  //     }
    
  //   })
  // }

  getProduct(man_no){
    let obj = {}
    obj['man_no']=man_no
    let url = this.api.baseAPIPath + '/mydrive/getProduct'

    this.http.post(url,obj)
    .subscribe((data: any) => {
      
      this.application_document=JSON.parse(data._body)
    
      if(this.application_document.length>0){
        this.showCompanyFolder=false
        this.showEmpCode=false
        this.showProductFolder=true
        this.showMonthFolder=false
        this.showFile=false
      }
    
    })
  }

  monthFrolderArr;
  getMonth(productName){
   
    let obj = {}
    obj['productName']=productName
    let url = this.api.baseAPIPath + '/mydrive/getAllMonth'

    this.http.post(url,obj)
    .subscribe((data: any) => {
      
      this.monthFrolderArr=JSON.parse(data._body)


      if(this.monthFrolderArr.length>0){
        this.showCompanyFolder=false
        this.showEmpCode=false
        this.showProductFolder=false
        this.showMonthFolder=true
        this.showFile=false
      }
    })
  }

  dataAccToMonth;
  getFolderMonth(folderMonth,product_key){
    let obj = {}
    obj['folderMonth']=folderMonth
    obj['product_key']=product_key
    let url = this.api.baseAPIPath + '/mydrive/getAllData'

    this.http.post(url,obj)
    .subscribe((data: any) => {
      
      this.dataAccToMonth=JSON.parse(data._body)
    

      if(this.dataAccToMonth.length>0){
        this.showCompanyFolder=false
        this.showEmpCode=false
        this.showProductFolder=false
        this.showMonthFolder=false
        this.showFile=true
      }
    
    })
  }


  onrightClick(even){

  }
  opensubfolder(data,text){

  }
  addfolder(){

  }
  edituserprofile(data,a){

  }

  reviewmodel(data,copy,tex){
    
  }
  deleteuser(data,i){

  }
  permissionmodel(data){

  }
  exportFolderWithZip(data, i){

  }
  importFolderWithZip(data, i){

  }
  viewmodel(data,p){
    this.previewfile(data)
  }

  previewfile(data) {
    // this. == ''
    this.url = environment.apiBaseURL + '/' + data.doc_url
    // let pdfUrl = this.pdfsrc
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
