import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, } from "@angular/router";
import { Http, Response } from '@angular/http';
import { environment } from '../../../environments/environment';
import { ApiServiceService } from 'src/app/api-service.service';
import { FolderService } from 'src/app/dashboard/explorer/folder/folder.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  env: any;;
  user: any;
  constructor(private router: Router,
    private http: Http,public api: ApiServiceService, private folderapi:FolderService ) { 
      this.user = {};
    }

  
  user_id: any
  user_type

  Configuration = [
    { _id: "1", name: "Configuration", page:"Configuration" },
    { _id: "2", name: "LDAP Configuration",page:"" },
    { _id: "3", name: " Upload Company Logo ",page:"companylogopage" },
    { _id: "4", name: "Change Wallpaper",page:"wallpaper" },
    { _id: "5", name: "Folder Watcher",page:"" },
    { _id: "6", name: "Settings",page:"" },
  ]

  User = [
    { _id: "1", name: "User Summary", page:"user" },
    { _id: "2", name: "Logged Users",page:"LoggedUser" },
    { _id: "3", name: "  User Profile ",page:"userprofile" },
    { _id: "4", name: "Roles",page:"managerole" },
    { _id: "5", name: "Roles Mapping ",page:"" },
    { _id: "6", name: "Profile mapping",page:"" },
  ]

activation = [
    { _id: "1", name: "License Generation", page:"" },
    { _id: "2", name: "Manage License",page:"" },
    { _id: "3", name: "Manage notifaction ",page:"" },
    { _id: "5", name: "Folder Mapping ",page:"" },
    { _id: "6", name: "File Type mapping",page:"" },
  ]

  ngOnInit() {
    let login_user = JSON.parse(localStorage.getItem("USER"));
    this.user_type = login_user.User_type
    this.user_id = login_user._id
    this.getuserdata()
  }

  // ####### -------------- start ------------------ ######

  gotopage(page) {
    if (page == "login") {
      localStorage.clear();
      this.router.navigateByUrl('/' + page)
    } else {
      this.router.navigateByUrl('/' + page)
    }
  }

  // get login page data 

  getuserdata() {
    let url = `/userlogin/userdata`
    let obj: any = {
      _id: this.user_id,
      internluser:true,
    }
    this.folderapi.postMethod(url, obj)
      .subscribe((data: any) => {
      
        this.user = data.data
      })
  }

  // ###### ----------------- end ---------------- #####

}




