import { Injectable,EventEmitter } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { Subscription } from 'rxjs/internal/Subscription';  
import { environment } from "../environments/environment";
declare const $: any;
@Injectable({
  providedIn: 'root'    
})

export class DDMS { 

  invokeFirstComponentFunction = new EventEmitter(); 
  invokeFirstComponentFunction2 = new EventEmitter(); 
  invokeFirstComponent = new EventEmitter();
  supSubVar: Subscription;
  subsVar: Subscription;  
  subs: Subscription;   
  environment = environment.apiBaseURL
  _id : any
    constructor( public http: Http){}

    ngOnInit() {
      this._id = (localStorage.getItem("USER"))
     
      // this.loginuser()
    }

    // loginuser(){
    //   let url =  this.environment + '/user/documnent'
    //    let obj = {

    //    }
    // }

   
    onFirstComponentButtonClick() {  
       
      this.invokeFirstComponentFunction.emit();    
    }  
    onFirstComponentButtonClick2() {  
       
      this.invokeFirstComponentFunction2.emit();    
    }  
    onFirstComponentButton() {  
       
      this.invokeFirstComponent.emit();    
    }  

    getPageTitle(pagename) {
        var titles = {
            UserProfile: "User-Profile | Digital Document Management System",
            MailAccount: "Mail-Account | Digital Document Management System",
            Explorer:"Explorer | Digital Document Management System",
            Log:"Log-in | Digital Document Management System",
            Register:"Register-Page | Digital Document Management System",
            Dashboard:"Dashboard | Digital Document Management System",
            Forgot:"Forgot | Digital Document Management System",
            Setting:"Setting | Digital Document Management System",
            Configuration:"Configuration | Digital Document Management System",
            Companylogopage:"Company-Logo | Digital Document Management System",
            Wallpaper:"Wallpaper | Digital Document Management System",
            Myprofile:"Myprofile | Digital Document Management System",
            LoggedUser:"LoggedUser | Digital Document Management System",
            Managerole:"Managerole | Digital Document Management System",
            Administration:"Administration | Digital Document Management System",
            User:"User | Digital Document Management System",
            Help:"Help | Digital Document Management System",
            Search:"Search | Digital Document Management System",
            MetaDataSummary:"Metadata-Summary | Digital Document Management System",
            MetaDataGroup:"Add-Metadata-Group | Digital Document Management System",
            FolderMapping:"Get-FolderMappings | Digital Document Management System",
    };
    return titles[pagename];
    }


    destroyTable(tableid) {
        try {
          $("#" + tableid)
            .DataTable()
            .destroy();
        } catch (err) {
          console.log(err);
        }
      }

      reRenderTable(tableid) {
        this.destroyTable(tableid);
    
        var self = this;
        setTimeout(function() {
          self.initializeTable(tableid);
        }, 500);
      }
      initializeTable(tableid) {
        this.initializeTableWithOption(tableid, {
          pagingType: "full_numbers",
          lengthMenu: [[25, 50, 100, -1], [25, 50, 100, "All"]],
          language: {
            search: "_INPUT_",
            searchPlaceholder: "Search records"
          }
        });
      }

      initializeTableWithOption(tableid, options) {
        $("#" + tableid).DataTable(options);
      }
}